<template>
    <div class="container-card-cuota">
      <div>
        <div class="step-icon">
          <img src="../assets/icon-marketplace.svg" alt="Id Card">
        </div>
        <h2 class="title mb-4">Mis créditos</h2>
        <p class="subtitle-container">Selecciona el crédito que deseas abonar.</p>
      </div>
      <div class="container-form">
        <ComercioCredito
            @click="comercio.totalDebt > 0 ? seleccionarComercio(comercio) : ''"
            class="mb-4"
            :key="index" 
            v-for="(comercio, index) in comerciosCredito" 
            :comercio="comercio"
            :index="index"
        />
        <ErrorsApi/>
      </div>
    </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import ErrorsApi from '../components/ErrorsApi'
import ComercioCredito from '../components/ComercioCredito.vue'
export default{
  components: {
    ErrorsApi,
    ComercioCredito,
  },
  computed:{
    ...mapState(['comerciosCredito'])
  },
  mounted(){
    this.agregarComercioCredito(null);
    this.gestionarValidarTokenSMS(null);
    this.gestionarValidarEnvioTokenSMS(null);
    this.agregarIdProntoPago('');
    this.gestionarShowArrowBack(null);
    this.beneficioQuacSeleccionado(0);
    if(this.comerciosCredito.length == 1)
    {
      this.seleccionarComercio(this.comerciosCredito[0]);
    }
    if (this.$store.state.tempNit != '') {
      this.comerciosCredito.forEach(element => {
        if (element.nitBusiness.toString() == this.$store.state.tempNit) {
          this.seleccionarComercio(element)
        }
      });
      this.$store.state.tempNit = ''
    }
  },
  methods:{
    ...mapActions(['numberFormat','agregarProcesoAnterior']),
    ...mapMutations(['cambiarProceso','agregarProcesoAnterior','gestionarValidarTokenSMS','gestionarShowArrowBack','gestionarValidarEnvioTokenSMS','beneficioQuacSeleccionado','agregarIdProntoPago','agregarComercioCredito', 'agregarValorAbono']),
    numberFormat(val){
      return new Intl.NumberFormat("es-CO", {minimumFractionDigits: 0,currency: 'COP',style: 'currency'}).format(val);
    },
    seleccionarComercio(comercio)
    {
      if(!comercio.allowPayment)
      {
        alert('Este comercio no admite pago en línea. Por favor, realiza tu abono directamente en el establecimiento asociado.');
        return;
      }
      this.agregarComercioCredito(comercio);
      this.agregarProcesoAnterior('seleccionar-comercio');
      this.cambiarProceso('seleccionar-cuota');
    }
  }
}
</script>
<style lang="scss" scoped>
.container-form {
  max-width: 100%;
}
.state-acount{
  border: 2px transparent solid;
}
.state-acount:hover{
  border: 2px #802EFF solid;
  cursor: pointer;
}
.button-pay-quota.anticipado{
  border: 2px transparent solid;
}
.button-pay-quota.anticipado:hover{
  border: 2px #FFC107 solid;
}
.cupo_al_dia{
  background-color: #E1F6EA;
  padding: 1rem;
}
</style>