<template>
    <div class="container-modal-info" id="modalEstrategiaReventa">
      <img
        src="@/assets/icon-close.svg"
        class="close-btn"
        @click="closeModalEstrategiaReventa()"
      />
      <div
        class="container-header-notification"
        :style="{ 'background-color': infoNotification.background_header }"
      >
        <h3
          class="title-header-notification"
          v-html="infoNotification.titulo"
        ></h3>
        <p
          class="text-header-notification"
          v-html="infoNotification.subtitulo"
        ></p>
      </div>
      <div
        class="container-body"
        :style="{ 'background-color': infoNotification.background_body }"
      >
        <p class="text-center mb-2"><b>Usa el siguiente código</b></p>
        <div class="container-copy-code">
          <p id="copy-cod" class="text-copy-code col">
            {{ infoNotification.cupon_codigo }}
          </p>
          <button
            class="btn-copy-code"
            :style="copiedCode ? 'background-color: #c6ecbb' : ''"
            @click="!copiedCode ? copiarTexto() : ''"
          >
            {{ copiedCode ? 'Copiado!' : 'Copiar' }}
          </button>
        </div>
        <b-button
          style="width: 250px"
          variant="primary"
          class="mx-auto d-block px-5 mb-3"
          @click="$router.push({ name: 'AllCategory' })"
        >
          Comprar ahora
        </b-button>
        <div class="mb-3">
          <p
            id="text-regresive-count"
            class="text-center"
            style="font-weight: bold"
          ></p>
        </div>
        <router-link
          class="d-block fw-14 text-left"
          style="color: black"
          :to="{}"
        >
          *Aplican TyC
        </router-link>
      </div>
    </div>
  </template>
  <script>
import { mapMutations } from 'vuex'
  export default {
    props: {
      infoNotification: {
        type: Object,
        required: true,
        default: () => {},
      },
    },
    data() {
      return {
        copiedCode: false,
        counter: 30,
      }
    },
    mounted() {
      this.startTimer(this.infoNotification.conteo_regresivo)
    },
    methods: {
      ...mapMutations(['cambiarEstadoModalCuponEstrategiaReventa']),
      async startTimer(time) {
        let textClose = document.getElementById('text-regresive-count')
        this.counter = time
        if (time > 0 && this.counter > 0) {
          if (textClose) {
            textClose.innerHTML = 'No lo dejes pasar: ' + time.toString()
          }
          await setTimeout(() => {
            if (this.counter > 0) {
              this.startTimer(time - 1)
            }
          }, 1000)
        } else {
          this.$emit('closeModal', false)
          this.counter = 0
        }
      },
      async copiarTexto() {
        var aux = document.createElement('input')
        aux.setAttribute('value', document.getElementById('copy-cod').innerText)
        document.body.appendChild(aux)
        aux.select()
        document.execCommand('copy')
        document.body.removeChild(aux)
        this.copiedCode = true
        await setTimeout(() => {
          this.copiedCode = false
        }, 4000)
      },
      async closeModalEstrategiaReventa(){
        var modal = document.getElementById('modalEstrategiaReventa');
        modal.style.bottom = '-100%';
        setTimeout(() => {
            this.cambiarEstadoModalCuponEstrategiaReventa(false);
            this.counter = 0;
        },1000);
      }
    },
  }
  </script>
  <style lang="scss">
  .container-modal-info {
    position: fixed;
    bottom: 0;
    right: .5rem;
    z-index: 1000;
    background-color: white;
    color: black;
    min-width: 360px;
    border-radius: 7px;
    overflow: hidden;
    box-shadow: 0 0 3px 2px #00000010;
    transition: all 1s ease-out;
    .close-btn {
      position: absolute;
      top: .5rem;
      right: .5rem;
      cursor: pointer;
      max-width: 25px;
    }
    .container-header-notification {
      background-color: #7df9e8;
      padding: 1rem;
      .title-header-notification {
        font-weight: bold;
        color: black;
        font-size: 24px !important;
        padding-right: 2rem;
        text-align: center;
      }
      .text-header-notification {
        font-weight: bold;
        margin-bottom: 0;
        color: black;
      }
    }
    .container-body {
      background-color: #73e9d9;
      padding: 1rem;
      .container-copy-code {
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 250px;
        height: 40px;
        margin-right: auto;
        margin-left: auto;
        border-radius: 5px;
        border: 1px solid black;
        margin-bottom: 1.5rem;
        background-color: white;
        .text-copy-code {
          margin-bottom: 0;
          text-align: center;
          font-weight: bold;
        }
        .btn-copy-code {
          height: 38px;
          padding-right: .6rem;
          padding-left: .6rem;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          border: none;
          border-left: 1px solid black;
          background-color: white;
          transition: all .4s linear;
        }
      }
    }
    @media (max-width: 768px) {
      right: 0;
    }
  }
  </style>
  