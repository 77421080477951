<template>
    <div class="container-card-cuota" style="max-width: 430px">
        <h2 class="mb-3">Método de pago seleccionado</h2>

        <div class="container-method-payment">
            <div class="d-flex align-items-center">
                <div class="mr-3">
                    <img src="@/assets/icon-tarjeta.svg" alt="" width="40">
                </div>
                <div class="text-left">
                    <p class="m-0 font-weight-bold" style="font-size: 18px">Tarjeta crédito o débito</p>
                </div>
            </div>
            <div class="text-right">
                <p class="m-0" style="font-size: 14px">Total a pagar</p>
                <p class="m-0 font-weight-bold" style="font-size: 18px" v-text="numberFormat(this.valorAbono)"></p>
            </div>
        </div>
        
        <div v-if="typeFormCard === 3" class="container-form">
            <form id="form-checkout">
                <label>Número de tarjeta</label>
                <div id="form-checkout__cardNumber" class="input-quac_tj"></div>
                <div class="d-flex">
                    <div class="col-md-6 pr-1 pl-0">
                        <label>Fecha de vencimiento</label>
                        <div id="form-checkout__expirationDate" class="input-quac_tj" />
                    </div>
                    <div class="col-md-6 pl-1 pr-0">
                        <label>CVV</label>
                        <div id="form-checkout__securityCode" class="input-quac_tj" />
                    </div>
                </div>
                <select id="form-checkout__installments" class="input-quac_tj mb-1"></select>
                <p class="text-left" style="font-size:13.5px;color:#707071;"><em>La opción de pago a cuotas no está disponible para tarjetas débito.</em></p>
                <select id="form-checkout__issuer" class="input-quac_tj"></select>
                <label>Datos del pagador</label>
                <div class="d-flex">
                    <div class="col-md-6 pr-1 pl-0">
                        <select id="form-checkout__identificationType" class="input-quac_tj"></select>
                    </div>
                    <div class="col-md-6 pl-1 pr-0">
                        <input type="text" id="form-checkout__identificationNumber" class="input-quac_tj" />
                    </div>
                </div>
                <label>Nombre y apellido como aparece en la tarjeta</label>
                <input type="text" id="form-checkout__cardholderName" class="input-quac_tj" />
                <input type="email" id="form-checkout__cardholderEmail" class="input-quac_tj d-none" />

                <ErrorsApi />
                <button type="submit" id="form-checkout__submit" class="button-primary my-3">Pagar</button>
            </form>
            <div class="cont_link_volver">
                <a href="#" @click.prevent="$router.back()">Volver</a>
            </div>
        </div>

        <div v-else class="container-form">
            <form action="#" method="#" @submit.prevent="pagarCuotaTarjetaForm()">

                <div class="text-left" style="position:relative;">
                    <div class="cont_franquicia">
                        <img src="@/assets/codensa.jpg" v-if="this.franquiciaTarjeta == 'codensa'"/>
                        <img src="@/assets/visa.svg" v-if="this.franquiciaTarjeta == 'visa'"/>
                        <img src="@/assets/amex.svg" v-if="this.franquiciaTarjeta == 'amex'"/>
                        <img src="@/assets/diners.svg" v-if="this.franquiciaTarjeta == 'diners'"/>
                        <img src="@/assets/mastercard.svg" v-if="this.franquiciaTarjeta == 'mastercard'"/>
                        <img src="@/assets/discover.png" v-if="this.franquiciaTarjeta == 'discover'"/>
                    </div>
                    <label for="nro_tarjeta" id="label_nro_tarjeta">Número de tarjeta</label>
                    <input id="nro_tarjeta"
                        placeholder="Número de tarjeta"
                        @keydown="filterKey"
                        v-model="formatCardNumber" 
                        @input="updateValue"
                        @blur="obtenerFranquiciaTarjeta(formatCardNumber.replace(/\s/g, '')); validateNroTarjeta();"
                        type="text"
                        required=""
                        class="input-quac mb-1"
                        maxlength="19">
                    <p id="message-error--tarjeta" style="display:none;font-size:13.5px;">Número de tarjeta inválido.</p>
                </div>
                <div class="row mt-3">

                    <div class="text-left col-md-8">
                        <label for="mes_vencimiento" id="label_fecha_vencimiento">Fecha de vencimiento</label>
                        <div class="d-flex">
                            <select id="mes_vencimiento" class="select-quac" v-model="mesVencimiento" required="" @blur="validateMesVencimiento()">
                                <option value="" disabled="" selected="">MM</option>
                                <option value="01">01</option>
                                <option value="02">02</option>
                                <option value="03">03</option>
                                <option value="04">04</option>
                                <option value="05">05</option>
                                <option value="06">06</option>
                                <option value="07">07</option>
                                <option value="08">08</option>
                                <option value="09">09</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                            </select>
                            <div class="mt-3 mx-2">
                                <span>/</span>
                            </div>
                            <select class="select-quac" id="anio_vencimiento" v-model="anioVencimiento" required="" @blur="validateAnioVencimiento()">
                                <option value="">AA</option>
                                <option :value="new Date().getFullYear()">
                                    {{ new Date().getFullYear() }}
                                </option>
                                <option :value="new Date().getFullYear() + 1">
                                    {{ new Date().getFullYear() + 1 }}
                                </option>
                                <option :value="new Date().getFullYear() + 2">
                                    {{ new Date().getFullYear() + 2 }}
                                </option>
                                <option :value="new Date().getFullYear() + 3">
                                    {{ new Date().getFullYear() + 3 }}
                                </option>
                                <option :value="new Date().getFullYear() + 4">
                                    {{ new Date().getFullYear() + 4 }}
                                </option>
                                <option :value="new Date().getFullYear() + 5">
                                    {{ new Date().getFullYear() + 5 }}
                                </option>
                                <option :value="new Date().getFullYear() + 6">
                                    {{ new Date().getFullYear() + 6 }}
                                </option>
                                <option :value="new Date().getFullYear() + 7">
                                    {{ new Date().getFullYear() + 7 }}
                                </option>
                                <option :value="new Date().getFullYear() + 8">
                                    {{ new Date().getFullYear() + 8 }}
                                </option>
                                <option :value="new Date().getFullYear() + 9">
                                    {{ new Date().getFullYear() + 9 }}
                                </option>
                                <option :value="new Date().getFullYear() + 10">
                                    {{ new Date().getFullYear() + 10 }}
                                </option>
                                <option :value="new Date().getFullYear() + 11">
                                    {{ new Date().getFullYear() + 11 }}
                                </option>
                                <option :value="new Date().getFullYear() + 12">
                                    {{ new Date().getFullYear() + 12 }}
                                </option>
                                <option :value="new Date().getFullYear() + 13">
                                    {{ new Date().getFullYear() + 13 }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="text-left col-md-4">
                        <label for="cvv" id="label_cvv">CVV</label>
                        <input
                            id="cvv"
                            placeholder="CCV"
                            type="text"
                            class="input-quac"
                            v-model="cvv"
                            maxlength="4"
                            @blur="validateCvv()"
                            @keypress="validarLongitudCvv">
                    </div>

                </div>

                <select name="numtarjeta" id="cuotas-tarjeta" class="select-quac mb-1" v-model="numeroCuotas" required="" @blur="validateNumeroCuotas()">
                    <option value="1">1 cuota</option>
                    <option value="2">2 cuotas</option>
                    <option value="3">3 cuotas</option>
                    <option value="4">4 cuotas</option>
                    <option value="5">5 cuotas</option>
                    <option value="6">6 cuotas</option>
                    <option value="7">7 cuotas</option>
                    <option value="8">8 cuotas</option>
                    <option value="9">9 cuotas</option>
                    <option value="10">10 cuotas</option>
                    <option value="11">11 cuotas</option>
                    <option value="12">12 cuotas</option>
                </select>
                <p class="text-left" style="font-size:13.5px;color:#707071;"><em>La opción de pago a cuotas no está disponible para tarjetas débito.</em></p>

                <div class="row align-items-end mt-4">
                <div class="text-left col-md-6 pe-md-2">
                    <label for="tipo_doc" id="label_datos_pagador">Datos del pagador</label>
                    <select id="tipo_doc" class="select-quac" v-model="tipoDocumentoCliente" required="" @blur="validateTipoDocumentoCliente()">
                        <option value="" disabled="" selected="">Tipo documento</option>
                        <option value="CC">CC</option>
                        <option value="CE">CE</option>
                        <option value="PAS">PAS</option>
                        <option value="NIT">NIT</option>
                    </select>
                </div>
                <div class="col-md-6 ps-md-2">
                    <input id="documento-cliente" type="number" class="input-quac" placeholder="Documento" v-model="documentoCliente" required="" @blur="validateDocumentoCliente()">
                </div>
                </div>

                <div class="text-left">
                    <label for="nombre_titular" id="label_nombre_titular">Nombre y apellido como aparece en la tarjeta</label>
                    <input
                        autocomplete="off"
                        id="nombre_titular"
                        placeholder="Nombre y apellido como aparece en la tarjeta"
                        type="text"
                        class="input-quac"
                        v-model="nombreCliente"
                        required=""
                        @blur="validateNombreCliente()">
                </div>

                <ErrorsApi />
                
                <button class="button-primary mb-3" @click="pagarCuotaTarjetaForm()">Pagar</button>
        
                <div class="cont_link_volver">
                    <a href="#" @click.prevent="$router.back()">Volver</a>
                </div>
            </form>
        </div>


    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import ErrorsApi from '../components/ErrorsApi.vue';
import $ from 'jquery'
export default{
    components:{
      ErrorsApi,
    },
    data(){
        return {
            nCreditCard: "",
            nombreCliente: '',
            tipoDocumentoCliente: '',
            documentoCliente: '',
            mesVencimiento: '',
            anioVencimiento: '',
            cvv: '',
            numeroCuotas: '1',
        }
    },
    mounted() {
        this.gestionarValidarTokenSMS(null);
        this.createForm();
    },
    unmounted(){
        window.mp = null
        console.log('cerrar')
    },
    methods:{
        createForm(){
            console.log('create')
            const mp = new window.MercadoPago("APP_USR-b992e9b8-7bed-4071-bd64-3d3f5485e543");
            const cardForm = mp.cardForm({
                amount: this.valorAbono.toString(),
                iframe: true,
                form: {
                    id: "form-checkout",
                    cardNumber: {
                        id: "form-checkout__cardNumber",
                        placeholder: "Numero de tarjeta",
                    },
                    expirationDate: {
                        id: "form-checkout__expirationDate",
                        placeholder: "MM/YY",
                    },
                    securityCode: {
                        id: "form-checkout__securityCode",
                        placeholder: "Código de seguridad",
                    },
                    cardholderName: {
                        id: "form-checkout__cardholderName",
                        placeholder: "Titular de la tarjeta",
                    },
                    issuer: {
                        id: "form-checkout__issuer",
                        placeholder: "Banco emisor",
                    },
                    installments: {
                        id: "form-checkout__installments",
                        placeholder: "Cuotas",
                    },        
                    identificationType: {
                        id: "form-checkout__identificationType",
                        placeholder: "Tipo de documento",
                    },
                    identificationNumber: {
                        id: "form-checkout__identificationNumber",
                        placeholder: "Número del documento",
                    },
                    cardholderEmail: {
                        id: "form-checkout__cardholderEmail",
                        placeholder: "E-mail",
                    },
                },
                callbacks: {
                    onFormMounted: error => {
                        if (error) return console.warn("Form Mounted handling error: ", error);
                        console.log("Form mounted");
                    },
                    onSubmit: event => {
                        event.preventDefault();
                        const {
                            paymentMethodId,
                            issuerId,
                            token,
                            installments,
                            identificationNumber,
                        } = cardForm.getCardFormData();

                        this.pagarCuotaTarjeta({
                            paymentMethodID: paymentMethodId,
                            securityCode: '',
                            cardExpirationYear: '',
                            cardholderName: '',
                            docNumber: identificationNumber,
                            cardNumber: '',
                            cardExpirationMonth: '',
                            docType: '',
                            installments: installments,
                            issuerID: issuerId,
                            token: token
                        });
                    },
                    onFetching: (resource) => {
                        console.log("Fetching resource: ", resource);
                    }
                },
            });
        },
        ...mapMutations(['cambiarProceso','gestionarValidarTokenSMS', 'agregarRespuestaTransaccion']),
        updateValue(e){
            this.nCreditCard = e.target.value.replace(/ /g, "");
        },
        ...mapActions(['pagarCuotaTarjeta','obtenerFranquiciaTarjeta']),
        pagarCuotaTarjetaForm(){
            if(    
                !this.nCreditCard ||
                this.nCreditCard.toString().length > 16 ||
                this.nCreditCard.toString().length < 12
            ){
                $("#nro_tarjeta").addClass('input-danger');
                $("#label_nro_tarjeta").addClass('text-danger');
                $("#message-error--tarjeta").show();
                $("#nro_tarjeta").focus();
                return false;
            }else{
                $("#message-error--tarjeta").hide();
                $("#nro_tarjeta").removeClass('input-danger');
                $("#label_nro_tarjeta").removeClass('text-danger');
            }
            if(this.mesVencimiento == null || this.mesVencimiento == undefined || this.mesVencimiento == ''){
                $("#mes_vencimiento").addClass('input-danger');
                $("#label_fecha_vencimiento").addClass('text-danger');
                $("#mes_vencimiento").focus();
                return false;
            }else{
                $("#label_fecha_vencimiento").removeClass('text-danger');
                $("#mes_vencimiento").removeClass('input-danger');
            }
            if(this.anioVencimiento == null || this.anioVencimiento == undefined || this.anioVencimiento == ''){
                $("#anio_vencimiento").addClass('input-danger');
                $("#label_fecha_vencimiento").addClass('text-danger');
                $("#anio_vencimiento").focus();
                return false;
            }else{
                $("#label_fecha_vencimiento").removeClass('text-danger');
                $("#anio_vencimiento").removeClass('input-danger');
            }
            if(this.cvv == null || this.cvv == undefined || this.cvv == '' || this.cvv.toString().length < 3){
                $("#cvv").addClass('input-danger');
                $("#label_cvv").addClass('text-danger');
                $("#cvv").focus();
                return false;
            }else{
                $("#label_cvv").removeClass('text-danger');
                $("#cvv").removeClass('input-danger');
            }
            if(this.numeroCuotas == null || this.numeroCuotas == undefined || this.numeroCuotas == ''){
                $("#cuotas-tarjeta").addClass('input-danger');
                $("#cuotas-tarjeta").focus();
                return false;
            }else{
                $("#cuotas-tarjeta").removeClass('input-danger');
            }
            if(this.tipoDocumentoCliente == null || this.tipoDocumentoCliente == undefined || this.tipoDocumentoCliente == ''){
                $("#tipo_doc").addClass('input-danger');
                $("#label_datos_pagador").addClass('text-danger');
                $("#tipo_doc").focus();
                return false;
            }else{
                $("#label_datos_pagador").removeClass('text-danger');
                $("#tipo_doc").removeClass('input-danger');
            }
            if(this.documentoCliente == null || this.documentoCliente == undefined || this.documentoCliente == '' || this.documentoCliente.toString().length < 4){
                $("#documento-cliente").addClass('input-danger');
                $("#label_datos_pagador").addClass('text-danger');
                $("#documento-cliente").focus();
                return false;
            }else{
                $("#label_datos_pagador").removeClass('text-danger');
                $("#documento-cliente").removeClass('input-danger');
            }
            if(this.nombreCliente == null || this.nombreCliente == undefined || this.nombreCliente == ''){
                $("#nombre_titular").addClass('input-danger');
                $("#label_nombre_titular").addClass('text-danger');
                $("#nombre_titular").focus();
                return false;
            }else{
                $("#label_nombre_titular").removeClass('text-danger');
                $("#nombre_titular").removeClass('input-danger');
            }
            this.pagarCuotaTarjeta({
                paymentMethodID: this.franquiciaTarjeta,
                securityCode: this.cvv,
                cardExpirationYear: this.anioVencimiento,
                cardholderName: this.nombreCliente,
                docNumber: this.documentoCliente,
                cardNumber: this.nCreditCard,
                cardExpirationMonth: this.mesVencimiento,
                docType: this.tipoDocumentoCliente,
                installments: this.numeroCuotas,
                token: '',
                issuerID: '',
            });
        },
        numberFormat(val){
            return new Intl.NumberFormat("es-CO", {minimumFractionDigits: 0,currency: 'COP',style: 'currency'}).format(val);
        },
        filterKey(e) {
            if(((e.keyCode < 45 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105)) && e.keyCode != 8) {
                e.preventDefault();
            }
        },
        validarLongitudCvv(e){
            if(((e.keyCode < 45 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105)) && e.keyCode != 8) {
                e.preventDefault();
            }
        },
        validateNroTarjeta(){
            $("#message-error--tarjeta").hide();
            if(
                !this.nCreditCard ||
                this.nCreditCard.toString().length > 16 ||
                this.nCreditCard.toString().length < 12
            ){
                $("#message-error--tarjeta").show();
                $("#nro_tarjeta").addClass('input-danger');
                $("#label_nro_tarjeta").addClass('text-danger');
            }else{
                $("#message-error--tarjeta").hide();
                $("#nro_tarjeta").removeClass('input-danger');
                $("#label_nro_tarjeta").removeClass('text-danger');
            }
        },
        validateMesVencimiento(){
            if(this.mesVencimiento == null || this.mesVencimiento == undefined || this.mesVencimiento == ''){
                $("#mes_vencimiento").addClass('input-danger');
                $("#label_fecha_vencimiento").addClass('text-danger');
            }else{
                $("#label_fecha_vencimiento").removeClass('text-danger');
                $("#mes_vencimiento").removeClass('input-danger');
            }
        },
        validateAnioVencimiento(){
            if(this.anioVencimiento == null || this.anioVencimiento == undefined || this.anioVencimiento == ''){
                $("#anio_vencimiento").addClass('input-danger');
                $("#label_fecha_vencimiento").addClass('text-danger');
            }else{
                $("#label_fecha_vencimiento").removeClass('text-danger');
                $("#anio_vencimiento").removeClass('input-danger');
            }
        },
        validateCvv(){
            if(this.cvv == null || this.cvv == undefined || this.cvv == '' || this.cvv.toString().length < 3){
                console.log('holaaa',this.cvv.toString());
                $("#cvv").addClass('input-danger');
                $("#label_cvv").addClass('text-danger');
            }else{
                $("#label_cvv").removeClass('text-danger');
                $("#cvv").removeClass('input-danger');
            }
        },
        validateNumeroCuotas(){
            if(this.numeroCuotas == null || this.numeroCuotas == undefined || this.numeroCuotas == ''){
                $("#cuotas-tarjeta").addClass('input-danger');
            }else{
                $("#cuotas-tarjeta").removeClass('input-danger');
            }
        },
        validateTipoDocumentoCliente(){
            if(this.tipoDocumentoCliente == null || this.tipoDocumentoCliente == undefined || this.tipoDocumentoCliente == ''){
                $("#tipo_doc").addClass('input-danger');
                $("#label_datos_pagador").addClass('text-danger');
            }else{
                $("#label_datos_pagador").removeClass('text-danger');
                $("#tipo_doc").removeClass('input-danger');
            }
        },
        validateDocumentoCliente(){
            if(this.documentoCliente == null || this.documentoCliente == undefined || this.documentoCliente == '' || this.documentoCliente.toString().length < 4){
                $("#documento-cliente").addClass('input-danger');
                $("#label_datos_pagador").addClass('text-danger');
            }else{
                $("#label_datos_pagador").removeClass('text-danger');
                $("#documento-cliente").removeClass('input-danger');
            }
        },
        validateNombreCliente(){
            if(this.nombreCliente == null || this.nombreCliente == undefined || this.nombreCliente == ''){
                $("#nombre_titular").addClass('input-danger');
                $("#label_nombre_titular").addClass('text-danger');
            }else{
                $("#label_nombre_titular").removeClass('text-danger');
                $("#nombre_titular").removeClass('input-danger');
            }
        },
    },
    computed: {
        ...mapState(['valorAbono','franquiciaTarjeta', 'typeFormCard']),
        formatCardNumber() {
            return this.nCreditCard != "" ? this.nCreditCard.match(/.{1,4}/g).join(" ") : "";
        },
    }
}
</script>

<style lang="scss">

#form-checkout {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    label {
        text-align: start;
    }
    .input-quac_tj {
        height: 18px;
        display: inline-block;
        border: 1px solid rgb(118, 118, 118);
        border-radius: 2px;
        padding-right: 1rem;
        padding-left: 1rem;
        min-height: 50px;
        border-radius: 15px;
        margin-bottom: 1rem;
        width: 100%;
    }
    .progress-bar {
        width: 100%;
        background-color: #E9F0FD;
    }
}

.container-method-payment{
    background-color: #E9F0FD;
    min-height: 70px;
    max-width: 430px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    border-radius: 10px;
    margin: 0 auto;
    margin-bottom: 2rem;
}
.cont_franquicia{
    position: absolute;
    right: 7px;
    top: 37px;
    opacity: .9;
    img{
        max-width: 50px
    }
}
.input-danger{
    border: 1px red solid;
}
#cuotas-tarjeta {
    text-align: center;
}
</style>