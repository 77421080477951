<template>
    <div class="container-card-cuota"  style="max-width: 430px">
        <h2 class="mb-3">Método de pago seleccionado</h2>

        <div class="container-method-payment d-flex justify-content-between align-items-center px-3 rounded mb-5 mx-auto">
            <div class="d-flex align-items-center">
                <div class="mr-3">
                    <img src="@/assets/icon-pse.png" alt="" width="54">
                </div>
                <div class="text-left">
                    <p class="m-0 font-weight-bold">PSE</p>
                    <p class="m-0" style="line-height: 1.1;">Transferencia electrónica</p>
                </div>
            </div>
            <div class="text-right">
                <p class="m-0" style="line-height: 1.1;">Total a pagar</p>
                <p class="m-0 font-weight-bold" v-text="numberFormat(this.valorAbono)"></p>
            </div>
        </div>

        <div class="container-form">
            <p class="mb-4"><b>Selecciona la entidad financiera</b></p>
            
            <p class="mb-5">Selecciona la entidad financiera para continuar con el pago, recuerda que debes tener una cuenta habilitada para realizar pagos por internet.</p>
    
            <select name="numtarjeta" id="num-tarjeta" class="select-quac" v-model="pseCodeSelect">
                <option value="" selected="" disabled="">Entidad financiera</option>
                <option :value="banco.pseCode" v-for="(banco,index) in bancosPSE" :key="index" v-text="banco.description"></option>
            </select>
            <p id="message-error" style="display:none;">Por favor seleccione la entidad financiera.</p>
            <ErrorsApi />
            <button class="button-primary mb-3" @click="pagarCuotaPSEForm()">Pagar con PSE</button>
    
            <div class="cont_link_volver">
                <a href="#" @click.prevent="$router.back()">Volver</a>
            </div>
        </div>

    </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import ErrorsApi from '../components/ErrorsApi.vue';
import $ from 'jquery'
export default{
    data(){
        return {
            pseCodeSelect: '',
        }
    },
    components:{
      ErrorsApi,
    },
    computed:{
        ...mapState(['valorAbono','bancosPSE'])
    },
    mounted() {
      this.gestionarValidarTokenSMS(null);
    },
    created(){
        this.obtenerBancosPSE();
    },
    methods:{
        ...mapMutations(['cambiarProceso','gestionarValidarTokenSMS']),
        ...mapActions(['pagarCuotaPSE','obtenerBancosPSE']),
        pagarCuotaPSEForm(){
            $("#num-tarjeta").removeClass('input-danger');
            $("#message-error").hide();
            if(this.pseCodeSelect){
                this.pagarCuotaPSE(this.pseCodeSelect);
            }else{
                $("#num-tarjeta").addClass('input-danger');
                $("#message-error").show();
            }
        },
        numberFormat(val){
            return new Intl.NumberFormat("es-CO", {minimumFractionDigits: 0,currency: 'COP',style: 'currency'}).format(val);
        },
    }
}
</script>
<style lang="scss">
.container-method-payment{
    background-color: #E9F0FD;
    min-height: 70px;
    max-width: 430px;
}
</style>