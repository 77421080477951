<template>
    <div>
        <div class="comercio row m-0" :class="!comercio.allowPayment || comercio.totalDebt == 0 ? 'disabled-card' : ''" :title="comercio.name">
            <div class="col pl-0 pr-0" style="place-self:center; max-width:35px; margin-right: .5rem;">
                <img :src="comercio.icon" alt="comercio.name">
            </div>
            <div class="col text-left pl-0">
                <p class="mb-0">Crédito</p>
                <div class="container-name-comercio" >
                    <p class="mb-0 fw-bold text-capitalize text-name" v-text="comercio.name"></p>
                    <div class="icon-info-img" :class="index == 0 ? 'show-mouse-hover' : ''" @mouseover="removeHover()">
                        <div class="toltip-info-comercio">
                            {{ comercio.description }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-4 p-0 text-left" v-if="comercio.minimumPayment > 0">
                <p class="mb-0">Pago mínimo</p>
                <p class="mb-0 fw-bold" v-text="numberFormat(comercio.minimumPayment)"></p>
            </div>
            <div class="container-tag col" v-else>
                <span>Al día</span>
            </div>
            <div class="col-1 pl-0 pr-0" style="place-self:center;" v-if="comercio.allowPayment && comercio.totalDebt > 0">
                <img src="@/assets/angle-right.svg" alt="angle-right" style="width:100%;">
            </div>
            <small v-if="!comercio.allowPayment" class="mt-2 comercio-disabled"><b>Este comercio no admite pago en línea</b></small>
            <small v-if="comercio.totalDebt == 0" class="mt-2 comercio-disabled"><b>Credíto no presenta saldo pendiente.</b></small>
        </div>
    </div>
</template>
<script>
export default {
  props: [
    'comercio',
    'index'
  ],
  methods:{
    numberFormat(val) {
      return new Intl.NumberFormat("es-CO", {minimumFractionDigits: 0,currency: 'COP',style: 'currency'}).format(val);
    },
    validarAliasComercio(alias) {
        let arrALias = alias.split(" ");
        return arrALias[0].toLowerCase();
    },
    removeHover() {
        document.querySelector('.icon-info-img')?.classList.remove('show-mouse-hover')
    }
  },
}
</script>
<style lang="scss">
.comercio{
    padding: 1.5rem;
    padding-right: 1rem;
    padding-left:1rem;
    box-shadow: 0px 2px 15px rgba(0,0,0,.1);
    border-radius: 20px;
    transition: all .1s ease-in-out;
    background-color: white;
    position: relative;
    .container-tag {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        span {
            padding: 0 .5rem;
            background-color: #00da9c;
            border-radius: 10px;
        }
    }
    &.disabled-card {
        padding-bottom: 2rem;
        &:hover{
            transform: scale(1);
        }
        .comercio-disabled {
            position: absolute;
            bottom: 0;
            text-align: center;
            width: 100%;
            left: 0;
            background-color: #E9E8F1;
            color: #374957;
            padding: .1rem 0;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
        }
    }
    &:hover{
        transform: scale(1.05);
        cursor: pointer;
    }
    img{
        width: 100%;
    }
    .container-name-comercio {
        display: flex;
        align-items: center;
        gap: 7px;
        position: relative;
        margin-right: auto;
        .text-name {
            line-height: 1.2;
            font-size: 15px;
        }
        .icon-info-img {
            min-width: 16px;
            height: 16px;
            background-image: url('../assets/icon-info.svg');
            background-repeat: no-repeat;
            background-position: center;
            position: relative;
            &.show-mouse-hover::before {
                content: '';
                background-image: url('../assets/icon-mouse-hover.svg');
                background-size: contain;
                background-repeat: no-repeat;
                position: absolute;
                top: 5px;
                left: 4px;
                height: 40px;
                width: 40px;
                z-index: 0;
                animation: hover-animation 2s linear infinite;
            }
            &::after {
                content: '';
                display: none;
                opacity: 0;
                position: absolute;
                z-index: 11;
                transition: all .1s linear;
                left: calc(50% - 8px);
                top: calc(-100% + 12px);
                width: 10px;
                height: 10px;
                border-top: 8px solid #2E1380;
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-bottom: 8px solid transparent;
            }
            &:hover {
                &::after {
                    opacity: 1;
                    display: block;
                    top: -11px;
                }
                .toltip-info-comercio{
                    opacity: 1;
                    z-index: 1;
                    display: block;
                    top: -10px;
                }

            }
            .toltip-info-comercio {
                position: absolute;
                display: none;
                right: -158px;
                opacity: 0;
                transition: all .1s linear;
                top: calc(-100% + 24px);
                transform: translateY(-100%);
                background-color: #2E1380;
                padding: 1rem;
                width: 300px;
                border-radius: 10px;
                color: white;
                font-size: 12px;
            }
        }
    }
}
</style>