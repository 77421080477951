import { mapState } from 'vuex';
<template>
    <div v-if="messageError " class="mt-3 mb-3">
        <p class="mb-0 text-danger text-center" v-text="messageError"></p>
    </div>
</template>
<script>
import { mapState } from 'vuex'
export default{
    data(){
        return {

        }
    },
    computed:{
        ...mapState(['errors','messageError'])
    }
}
</script>