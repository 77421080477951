<template>
    <section class="container-layout">
        <div class="container-content">
            <header class="header-base">
                <img src="../../assets/logo-quac2.svg" class="img-logo">
                <span class="go-back" v-if="showBack" @click="$emit('goBack', true)">
                    Volver
                </span>
            </header>
            <div class="container-content-form">
                <slot></slot>
            </div>
        </div>
        <div class="section-decorador-quac">
            <h1 class="title-page" id="title-page">{{ textTitle }}</h1>
            <footer class="content-copy">
                <p class="text-copy">© {{ new Date().getFullYear() }} POR CUOTAS SAS</p>
                <p class="text-tyc">
                    <a href="https://quac.co/legales/tyc" target="_blank">Términos y condiciones</a>
                    <span>|</span>
                    <a href="https://quac.co/legales/politica-proteccion-datos-personales" target="_blank">Política de privacidad</a>
                </p>
                <p class="text-promotion">QUAC es una marca registrada. Hecho con 💚 por POR CUOTAS SAS</p>
            </footer>
        </div>
    </section>
</template>
<script>
    export default {
        name: 'LayoutView',
        data() {
            return {
                textTitle: '',
                titlePage: [
                    '¿Sabías qué pagar a tiempo mejora tu historial crediticio? ¡Hazlo ahora!',
                    'Aceptamos todas las formas de pago. ¡Elige la tuya!',
                    'Paga fácilmente con Efecty o PSE a través de PayU. ¡Tu seguridad es nuestra prioridad!',
                    "Clientes Bancolombia: Paguen fácil con solo un clic usando el botón de Bancolombia.",
                    'Usa tu tarjeta de crédito para pagos rápidos y seguros.',
                    'Mantente al día con tus pagos y di adiós a los intereses moratorios.',
                    '¡Paga antes de tiempo y disfruta descuentos exclusivos!',
                    'Evita filas y esperas. Tu próximo pago está a un clic de distancia.',
                ],
                indexText: 0,
            }
        },
        props: {
            showBack: {
                type: Boolean,
                required: false,
                default: () => false,
            },
        },
        methods: {
            buildTitle(index) {
                this.textTitle = this.titlePage[this.indexText]?.slice(0,index)
                if (this.titlePage[this.indexText] && this.textTitle.length != this.titlePage[this.indexText].length) {
                    setTimeout(() => {
                        this.buildTitle(index+1)
                    }, 50);
                } else {
                    let title = document.getElementById('title-page')
                    setTimeout(() => {
                        title ? title.style.animation = 'fadeTitle 1s linear' : ''
                    }, 2000);
                    setTimeout(() => {
                        title ? title.style.animation = '' : ''
                        if (this.indexText == (this.titlePage.length - 1)) {
                            this.indexText = 0
                        } else {
                            this.indexText += 1
                        }
                        this.textTitle = ''
                        this.buildTitle(1)
                    }, 2950);
                }
            },
        },
        mounted() {
            this.buildTitle(1)
        },
    }
</script>
<style lang="scss" scoped>
    .container-layout {
        background-color: #F4F6FB;
        display: grid;
        grid-template-columns: 1fr 1fr;
        min-height: 100dvh;
        max-width: 1920px;
        margin: auto;
        .container-content {
            margin-top: 1rem;
            margin-left: 1rem;
            border-top-left-radius: 10px;
            padding: 2.5rem 3rem;
            background-color: white;
            .header-base {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 4rem;
                position: relative;
                z-index: 2;
                .img-logo {
                    min-width: 100px;
                }
                .go-back {
                    color: #7633FF;
                    cursor: pointer;
                    transition: all .2s linear;
                    &::before {
                        content: url('../../assets/icon-row.svg');
                        padding-right: .2rem;
                    }
                    &:hover {
                        transform: scale(1.09);
                    }
                }
            }
            &-form {
                width: 80%;
                margin-left: auto;
                margin-right: auto;
                position: relative;
                z-index: 2;
            }
        }
        .section-decorador-quac {
            background-color: #2E1380;
            background-size: 110%;
            position: relative;
            z-index: 1;
            overflow: visible;
            padding: 3rem;
            display: flex;
            flex-wrap: wrap;
            color: #FFFFFF;
            &::after {
                content: '';
                background-image: url('../../assets/background_layout__1.svg');
                width: 115%;
                height: 100%;
                position: absolute;
                bottom: 0;
                right: 0;
                background-position: bottom right;
                background-size: 100%;
                background-repeat: no-repeat;
            }
            .title-page {
                margin-top: auto;
                margin-bottom: auto;
                position: relative;
                z-index: 1;
                max-width: 553px;
            }
            .content-copy {
                margin-top: auto;
                text-align: center;
                width: 100%;
                position: relative;
                z-index: 1;
                display: block;
                color: white;
                span {
                    color: inherit;
                }
                .text- {
                    &copy {
                        font-size: 14px;
                        color: inherit;
                        margin-bottom: .5rem;
                    }
                    &tyc {
                        font-size: 14px;
                        margin-bottom: .5rem;
                        display: flex;
                        justify-content: center;
                        gap: 20px;
                        color: inherit;
                        a {
                            text-decoration: none;
                            color: inherit;
                            cursor: pointer;
                            font-size: inherit;
                        }
                    }
                    &promotion {
                        font-size: 10px;
                        color: inherit;
                    }
                }
            }
        }
        @media (max-width: 991px) {
            .container-content {
                padding: 2.5rem 2rem;
                &-form {
                    width: 100%;
                }
            }
        }
        @media (max-width: 768px) {
            grid-template-columns: 1fr;
            .container-content {
                margin-right: 1rem;
                border-top-right-radius: 10px;
                &-form {
                    width: 90%;
                }
            }
            .section-decorador-quac {
                background-size: 100%;
                .title-page {
                    margin-bottom: 3rem;
                    text-align: center;
                }
            }
        }
        @media (max-width: 500px) {
            .container-content {
                padding: 2.5rem 1rem;
                &-form {
                    width: 100%;
                }
            }
            .section-decorador-quac {
                padding: 3rem 2rem;
                .title-page {
                    min-height: 115.2px;
                }
                .content-copy {
                    .text- {
                        &copy {
                            margin-bottom: .5rem;
                        }
                        &tyc {
                            flex-wrap: wrap;
                            gap: 5px;
                            margin-bottom: .5rem;
                            span {
                                display: none;
                                height: 0 !important;
                            }
                        }
                    }
                }
            }
        }
    }
</style>