<template>
    <div class="container-card-cuota">
      <div class="step-icon">
        <img src="@/assets/icon-pronto-pago.svg" alt="piggy bank">
      </div>
      <h2 class="mb-3">Pronto Pago</h2>
      <p class="subtitle-container">Selecciona una o más facturas y obtén un descuento por pago anticipado.</p>
      <div class="container-form">
        <div class="container-pronto-pago">
          <div v-for="(prontoPago, index) in comercioCredito.prontopagos.facturas" :key="index" class="item-pronto-pago">
            <div
              class="checkbox-quac"
              :class="facturasProntopagoSelect.some(e => e.id === prontoPago.id) ? 'checkbox-quac-select' : ''"
              @click="agregarFacturaProntopago(prontoPago)"
            ></div>
            <p class="col text-pronto-pago">
              {{ prontoPago.titulo }} <br>
              <span>{{ prontoPago.subtitulo }}</span>
            </p>
            <div>
              <p class="value-pay">
                {{ numberFormat(prontoPago.valor_pago) }}
              </p>
            </div>
          </div>
        </div>
        <button class="button-primary" @click="pagarCuotaAbono()">Pagar {{ facturasProntopagoSelect.length > 0 ? numberFormat(this.valorAPagar) : '' }}</button>
        <div class="cont_link_volver">
          <a href="#" @click.prevent="$router.back()">Volver</a>
        </div>
      </div>
    </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
export default{
    data() {
      return {
        facturasProntopagoSelect: [],
      }
    },
    mounted() {
      this.gestionarValidarTokenSMS(null);
      this.gestionarShowArrowBack(true);
    },
    computed:{
      ...mapState(['comercioCredito']),
      valorAPagar(){
        var totalFacturas = 0;
        for (let index = 0; index < this.facturasProntopagoSelect.length; index++) {
          const element = this.facturasProntopagoSelect[index];
          totalFacturas = totalFacturas + parseFloat(element.valor_pago);
        }
        return totalFacturas;
      },
      facturasIdProntopagoSelect(){
        var facturas_id = [];
        for (let index = 0; index < this.facturasProntopagoSelect.length; index++) {
          facturas_id.push(this.facturasProntopagoSelect[index].id);
        }
        return facturas_id;
      },
    },
    methods:{
      ...mapMutations([
        'cambiarProceso',
        'agregarIdProntoPago',
        'agregarValorAbono',
        'agregarProcesoAnterior',
        'gestionarValidarTokenSMS',
        'gestionarShowArrowBack',
      ]),
      numberFormat(val){
        return new Intl.NumberFormat("es-CO", {minimumFractionDigits: 0,currency: 'COP',style: 'currency'}).format(val);
      },
      pagarCuotaAbono(){
        this.agregarIdProntoPago(this.facturasIdProntopagoSelect);
        this.agregarValorAbono(this.valorAPagar);
        this.agregarProcesoAnterior('pago-anticipado');
        this.cambiarProceso('metodo-pago');
      },
      agregarFacturaProntopago(prontopago){
        if(this.facturasProntopagoSelect.length <= 0 || !this.facturasProntopagoSelect.some(e => e.id === prontopago.id)){
          this.facturasProntopagoSelect.push(prontopago);
        }else{
          this.facturasProntopagoSelect = this.facturasProntopagoSelect.filter((item) => {
            return item.id != prontopago.id;
          });
        }
      },
    }
}
</script>
<style lang="scss" scoped>
  .value-pay {
    font-weight: bold;
  }
  .text-pronto-pago {
    span {
      color: #888888;
    }
  }
</style>