<template>
    <div class="container-card-cuota">
        <div class="step-icon">
            <img src="@/assets/id-card.svg" alt="Id Card">
        </div>
        <h2 class="mb-3">Validar identidad</h2>
        <p class="mb-3 subtitle-container"><b v-text="clienteNombre" style="color:#000;"></b>, para visualizar tu estado de cuenta ingresa el código que te hemos enviado por mensaje de texto al <b v-text="celularCliente" style="color:#802EFF;"></b>.</p>
        <div class="container-form mb-4" id="container-input-doc">
        <input type="number" placeholder="Código de verificación" class="input-quac mb-2" v-model="codigo" @keyup.enter="validarTokenEstadoCuentaForm()">
        <p id="message-error" style="display:none;">Por favor ingresa un código válido.</p>
        <button class="button-primary mt-2 mb-4" @click="validarTokenEstadoCuentaForm()">Continuar</button>
        <span
            id="countdown-text"
            :style="resendCod ? 'cursor: pointer;' : 'opacity: .6;'"
            @click="resendCod ? reenviarTokenEstadoCuentaCounterDown() : ''">
        </span>
        <div class="cont_link_volver">
            <a href="#" @click.prevent="$router.back()">Volver</a>
        </div>
        <ErrorsApi/>
      </div>
        
        <MediosPago />

        <CodigoInvalido />
    </div>
</template>
<script>
import ErrorsApi from '../components/ErrorsApi'
import { mapActions, mapMutations, mapState } from 'vuex'
import CodigoInvalido from './CodigoInvalido.vue'
import MediosPago from '../components/MediosPago.vue'
import $ from 'jquery'
export default{
    data(){
        return {
            codigo: null,
            resendCod: false,
        }
    },
    components:{
        ErrorsApi,
        CodigoInvalido,
        MediosPago,
    },
    mounted(){
        this.gestionarValidarTokenSMS(null);
        if(!this.validar_envio_token_sms) {
            this.cambiarProceso('seleccionar-cuota')
            this.$router.replace({name: 'seleccionar-cuota'})
        }
        this.gestionarShowArrowBack(true);
        this.agregarProcesoAnterior('seleccionar-cuota');
        this.counterDown(120);
    },
    computed:{
        ...mapState(['celularEnmascaradoCliente','documentoCliente','clienteNombre','celularCliente', 'validar_envio_token_sms','comercioCredito'])
    },
    methods:{
        ...mapActions(['validarTokenEstadoCuenta','reenviarTokenEstadoCuenta']),
        ...mapMutations(['agregarMessageError','cambiarProceso','gestionarValidarTokenSMS','gestionarShowArrowBack','agregarProcesoAnterior']),
        validarTokenEstadoCuentaForm(){
            $("#container-input-doc input").removeClass('input-danger');
            $("#message-error").hide();
            if(this.codigo && this.codigo.toString().length == 6){
                this.validarTokenEstadoCuenta({client_document: this.documentoCliente,code_token: this.codigo.toString(),client_phone: this.celularCliente,nit_business: this.comercioCredito.nitBusiness.toString()});
            }else{
                $("#container-input-doc input").addClass('input-danger');
                $("#message-error").show();
            }
        },
        counterDown(time) {
            let btnChange = document.getElementById('countdown-text')
            this.countTimeResendCodPagareSMS = time
            if (time > 0) {
                this.resendCod = false
                if (btnChange) {
                let min = parseInt(time/60);
                let seg = parseInt(time - (60 * min));
                btnChange.innerHTML = `Reenviar código en ${min}:${seg < 10 ? '0' + seg : seg}s`
                }
                setTimeout(() => {
                if (this.countTimeResendCodPagareSMS > 0 && this.$route.name == 'validar-identidad') {
                    this.counterDown(time - 1)
                }
                }, 1000)
            } else {
                btnChange.innerHTML = 'Reenviar código'
                this.resendCod = true
            }
        },
        async reenviarTokenEstadoCuentaCounterDown(){
            let tokenSend = await this.reenviarTokenEstadoCuenta();
            if(tokenSend) this.counterDown(120);
        }
    }
}
</script>
<style lang="scss">
.link_reenviar{
    cursor: pointer;
    font-weight: bold;
    color: #802EFF;
    font-size: 18px;
}
</style>