<template>
  <div class="container-card-cuota">
    <div class="step-icon">
      <img src="@/assets/method-pay-check.svg" alt="method pay check">
    </div>
    <h2 class="title mb-4">Selecciona el método de pago</h2>
    <div class="container-form container-form-v2 mb-2">
      <div class="state-acount button-pay-quota2">
        <div class="col-7 text-left">
          <p>Total a pagar</p>
          <p class="value-pay" style="font-size: 26px;" v-text="numberFormat(this.valorAbono)"></p>
        </div>
        <div class="col-5 text-center" v-if="payment_request_reference == ''">
          <p class="go-link" @click="$router.back()">Cambiar valor</p>
        </div>
      </div>
      <div v-for="(paymentInfo, index) in dataPaymentMethods" :key="index">
        <div class="container-medio-pago" :style="{ order: paymentInfo.priority }"
          @click="goToPaymentType(paymentInfo)">
          <div class="header-medio-pago">
            <div :style="{ backgroundColor: paymentInfo.icon_background }" class="icon-medio-pago">
              <img :src="paymentInfo.icon_url" alt="icono de pago">
            </div>
            <div class="col">
              <p class="text-title-medio">{{ paymentInfo.display_name }}</p>
              <p class="text-desc-medio">{{ paymentInfo.description }}</p>
            </div>
            <img src="@/assets/arrow-pay.svg" style="max-width: 9px; margin: 0 .5rem;">
          </div>
        </div>
      </div>
      <ErrorsApi />

      <div class="cont_link_volver mt-5" v-if="payment_request_reference == ''">
        <a href="#" @click.prevent="$router.back()">Volver</a>
      </div>

    </div>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import ErrorsApi from '../components/ErrorsApi.vue';
export default {
  components: {
    ErrorsApi,
  },
  data() {
    return {
      selectMedioPago: 0,
    }
  },
  mounted() {
    if (this.$route.name != 'seleccionar-cuota') {
      this.gestionarValidarTokenSMS(null);
    }
    this.gestionarShowArrowBack(true);
  },
  computed: {
    ...mapState(['clienteNombre', 'valorAbono', 'procesoAnterior', 'beneficioQuacSeleccionado', 'dataPaymentMethods', 'payment_request_reference'])
  },
  methods: {
    ...mapMutations(['cambiarProceso', 'gestionarValidarTokenSMS', 'gestionarShowArrowBack']),
    ...mapActions(['pagarCuotaBancolombia', 'pagarCuotaEfecty', 'pagarCuotaPSE']),
    numberFormat(val) {
      return new Intl.NumberFormat("es-CO", { minimumFractionDigits: 0, currency: 'COP', style: 'currency' }).format(val);
    },
    goToPaymentType(paymenttype) {
      this.$store.state.minPayment = paymenttype.min_value
      switch (paymenttype.name) {
        case 'bancolombia':
          this.pagarCuotaBancolombia();
          break;
        case 'efecty':
          this.cambiarProceso('metodo-pago-efecty');
          break;
        case 'nequi':
          this.pagarCuotaPSE('1507');
          break;
        case 'tarjeta':
          console.log(paymenttype.payment_vendor_id)
          this.$store.state.typeFormCard = paymenttype.payment_vendor_id
          this.cambiarProceso('metodo-pago-tarjeta');
          break;
        case 'pse':
          this.cambiarProceso('metodo-pago-pse');
          break;
      }
    },
    pagarEfectyForm() {
      if (this.valorAbono < 20000) return;
      this.cambiarProceso('metodo-pago-efecty');
    }
  },
}
</script>
<style lang="scss">
.container-medio-pago {
  margin-bottom: 1rem;
  padding-right: .7rem;
  transition: all .2s linear;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  border: 2px solid #E9F0FD;
  background: linear-gradient(90deg, white 88%, #e9f0fd 0);
  box-shadow: 1px 1px 3px 1px #00000010;

  &:hover {
    box-shadow: 0px 0px 5px 2px #00000010;
  }

  .header-medio-pago {
    display: flex;
    cursor: pointer;
    align-items: center;
    text-align: start;
    min-height: 60px;
    transition: all .3s linear;

    .icon-medio-pago {
      width: 60px;
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #EAF0FC;

      img {
        width: 43px;
      }
    }

    .text-desc-medio {
      margin-bottom: 0;
      font-size: 14px;
    }

    .text-title-medio {
      flex: 1 0 0%;
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 0;
    }
  }

  .body-medio-pago {
    height: 0 !important;
    overflow: hidden;
    transition: all .2s ease;
    padding: 0 1rem;
  }

  &-select {
    border: 2px solid #802EFF;

    .header-medio-pago {
      background-color: #EAF0FC;
    }

    .body-medio-pago {
      padding: 1.5rem 1rem;
      height: auto !important;
    }
  }
}

.button-pay-quota2 {
  cursor: default !important;
  background-color: #F0F0FF !important;

  &:hover {
    border-color: #F0F0FF !important;
    cursor: default !important;
  }

  .go-link {
    color: black;
  }
}
</style>