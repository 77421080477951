<template>
    <div class="container-card-cuota">
        <div class="step-icon">
            <img src="@/assets/wallet-card.svg" alt="Id Card">
        </div>
      <h2 class="mb-3">Ingresa el valor a pagar</h2>
      <p class="subtitle-container"><b v-text="clienteNombre" style="color:#802EFF;"></b> escribe el valor que deseas pagar.</p>
      <div class="container-form container-form-v2 mb-2">
        
        <div class="container-form">

        <div class="state-acount button-pay-quota2">
          <div class="col-12 text-center">
            <p>Deuda a la fecha</p>
            <p class="value-pay" style="font-size: 18px;" v-text="numberFormat(comercioCredito. totalDebt)"></p>
          </div>
        </div>
          <div class="container-input-doc mb-2" id="container-input-doc">
            <span>$</span>
            <input type="number" placeholder="Valor a pagar" v-model="valorPersonalizado" @keyup.enter="pagarCuotaAbono()">
          </div>
          <p id="message-error" style="display:none;" />
          <button class="button-primary mt-2" @click="pagarCuotaAbono()">Pagar {{ valorPersonalizado ? numberFormat(valorPersonalizado) : '' }}</button>
          <div class="cont_link_volver">
            <a href="#" @click.prevent="$router.back()">Volver</a>
          </div>
          <ErrorsApi/>
        </div>
      </div>
    </div>
</template>
<script>
import { mapMutations, mapState } from 'vuex'
import ErrorsApi from '../components/ErrorsApi.vue'
export default{
  components:{
    ErrorsApi,
  },
  data(){
        return {
          valorPersonalizado: null,
        }
    },
    computed:{
      ...mapState(['clienteNombre','valorAbono','procesoAnterior','comercioCredito'])
    },
    mounted(){
      this.gestionarValidarTokenSMS(null);
      this.gestionarShowArrowBack(true);
    },
    methods:{
      ...mapMutations(['cambiarProceso','agregarMessageError','agregarValorAbono','agregarProcesoAnterior','gestionarValidarTokenSMS','gestionarShowArrowBack']),
      numberFormat(val){
        return new Intl.NumberFormat("es-CO", {minimumFractionDigits: 0,currency: 'COP',style: 'currency'}).format(val);
      },
      pagarCuotaAbono(){
        this.agregarValorAbono(this.valorPersonalizado);
        this.agregarProcesoAnterior('valor-personalizado');
        this.cambiarProceso('metodo-pago');
      }
    }
}
</script>
<style lang="scss">
#container-input-doc input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.button-pay-quota2{
  cursor: default !important;
  &:hover{
    border-color: #e9f0fd;
    cursor: default !important;
  }
}
</style>