<template>
    <div class="modal fade center" id="modalPlanPagos" tabindex="-1" role="dialog" aria-labelledby="modalPlanPagosLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body m-4">
            <div @click="cerrarModal" class="btn-close">
                <img src="@/assets/times-solid.svg" alt="close" width="18">
            </div>
            <h4 class="text-center mb-3 fz-20">Mi plan de pagos en <span v-text="'crédito '+comercioCredito.alias"></span></h4>
            <p class="mb-5 mx-4 text-center">Encuentra todas las fechas y el valor del pago que debes realizar para estar al día.</p>
            
            <div class="header-plan mb-3 px-4">
                <div>
                    Próximos pago
                </div>
                <div>
                    Valor a pagar
                </div>
            </div>

            <div class="container-list-plan">
                <template v-for="(factura,index) in informacionEstadoCuenta.facturas" :key="index">
                    <div class="item-list">
                        <div>
                            <span v-text="formatDateTime(factura.vencimiento,'DD MMMM YYYY')"></span>
                        </div>
                        <div>
                            <span v-text="numberFormat(factura.saldo)"></span>
                        </div>
                    </div>
                </template>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import $ from 'jquery'
import moment from 'moment'
import { mapState } from 'vuex';
export default{
    computed: {
        ...mapState(['informacionEstadoCuenta','comercioCredito'])
    },
    methods:{
        cerrarModal(){
            $("#modalPlanPagos").modal('hide');
        },
        numberFormat(val){
            return new Intl.NumberFormat("es-CO", {minimumFractionDigits: 0,currency: 'COP',style: 'currency'}).format(val);
        },
        formatDateTime(datetime,format){
            return moment(datetime).format(format);
        }
    }
}
</script>
<style lang="scss">
#modalPlanPagos{
    .modal-content{
        border-radius: 16px;
    }
    .btn-close{
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
    }
    .header-plan{
        display: flex;
        justify-content: space-between;
        font-weight: bold;
    }

    color: #002061;
    .container-list-plan{
        background-color: #F9FBFA;
        padding: 1rem;
        border-radius: 10px;
        .item-list{
            display: flex;
            justify-content: space-between;
            border-top: 1px solid #D5D5D5;
            padding: 1rem;
            &:last-child{
                border-bottom: 1px solid #D5D5D5;
            }
        }
    }
}
</style>