<template>
    <div class="container-card-cuota">
      <div class="step-icon">
        <img src="@/assets/bill-card.svg" alt="Id Card">
      </div>
      <h2 class="mb-3">Estado de cuenta en <span v-text="'crédito '+comercioCredito.alias"></span></h2>
      <div class="container-form container-form-v2 mb-2">
        <div class="button-pay-quota button-pay-quota2 d-block">
          <p style="font-size:14px;" class="">Cliente:</p>
          <p class="fw-bold" v-text="informacionEstadoCuenta.nombres +' '+informacionEstadoCuenta.apellidos"></p>
          <p class="mb-0">C.C <span v-text="informacionEstadoCuenta.nit"></span></p>
        </div>
        <div class="state-acount button-pay-quota2">
          <div class="col-12 text-center">
            <p>Tu cupo disponible</p>
            <p class="value-pay" style="font-size: 26px;" v-text="numberFormat(informacionEstadoCuenta.disponible)"></p>
          </div>
        </div>
        <div class="state-acount button-pay-quota2">
          <div class="col-6 text-center" style="border-right: 1px solid #C5C3D8">
            <p>Has usado</p>
            <p class="value-pay" style="font-size: 26px;" v-text="numberFormat(informacionEstadoCuenta.cupo - informacionEstadoCuenta.disponible)"></p>
          </div>
          <div class="col-6 text-center">
            <p>Cupo total</p>
            <p class="value-pay" style="font-size: 26px;" v-text="numberFormat(informacionEstadoCuenta.cupo)"></p>
          </div>
        </div>
        <div class="button-pay-quota button-pay-quota2 row mr-0 ml-0" v-if="(informacionEstadoCuenta.cupo - informacionEstadoCuenta.disponible) > 0">
          <div class="col-md-5 col-12 text-center mb-3 mb-md-0">
            <p class="mb-1">Pago mínimo</p>
            <p class="value-pay mb-2" style="font-size: 26px;" v-text="numberFormat(informacionEstadoCuenta.pagoMinimo)"></p>
            <span class="state-cupo-badge" style="background-color: #FFD500" v-if="informacionEstadoCuenta.moroso > 0">En mora</span>
            <span class="state-cupo-badge text-white" style="background-color: #0BDA80" v-if="informacionEstadoCuenta.pagoMinimo <= 0">Al día</span>
          </div>
          <div class="col-md-7 col-12">
            <button class="button-primary" @click="pagarCuota()">Pagar cuota</button>
          </div>
        </div>
        <div class="state-acount text-center p-4 button-pay-quota2" v-else>
          <p class="mb-0">¡Felicitaciones <b v-text="clienteNombre"></b>! estás al día en tus pagos. </p>
        </div>
        <div class="button-pay-quota button-pay-quota2 row mr-0 ml-0" v-if="informacionEstadoCuenta.facturas.length > 0">
          <div class="col-md-5 col-12 mb-3 mb-md-0 text-center">
            <p class="mb-1">Próximo pago</p>
            <p class="value-pay mb-2 text-capitalize" style="font-size: 26px;" v-text="formatDateTime(informacionEstadoCuenta.proximoPago,'DD MMM, YY').replace('.','')"></p>
          </div>
          <div class="col-md-7 col-12">
            <button class="button-secondary" data-toggle="modal" data-target="#modalPlanPagos">Ver plan de pagos</button>
          </div>
        </div>
      </div>
    </div>
    <PlanPagos/>
</template>
<script>
import PlanPagos from './PlanPagos.vue'
import { mapMutations, mapState } from 'vuex'
import moment from 'moment'
export default{
  components:{
    PlanPagos,
  },
  data(){
    return{

    }
  },
  computed:{
    ...mapState(['informacionEstadoCuenta','clienteNombre', 'validar_token_sms','comercioCredito'])
  },
  mounted() {
    if(!this.validar_token_sms) {
        this.cambiarProceso('seleccionar-cuota')
        this.$router.replace({name: 'seleccionar-cuota'})
    }
    this.agregarProcesoAnterior('seleccionar-cuota');
    this.gestionarShowArrowBack(true);
  },
  methods:{
    ...mapMutations(['cambiarProceso','agregarProcesoAnterior','gestionarValidarTokenSMS','gestionarValidarEnvioTokenSMS','agregarProcesoAnterior','gestionarShowArrowBack']),
    numberFormat(val){
      return new Intl.NumberFormat("es-CO", {minimumFractionDigits: 0,currency: 'COP',style: 'currency'}).format(val);
    },
    pagarCuota(){
      this.agregarProcesoAnterior('estado-cuenta');
      this.cambiarProceso('seleccionar-cuota');
    },
    formatDateTime(datetime,format){
      return moment(datetime).format(format);
    }
  }
}
</script>
<style lang="scss">
.button-pay-quota2{
  cursor: default !important;
  &:hover{
    border-color: #e9f0fd;
    cursor: default !important;
  }
}
</style>