<template>
  <div class="container-page max-container">
    <component :is="procesoActual"></component>
  </div>
</template>
<script>
import ValidarDocumento from './ValidarDocumento.vue'
import ValidarCelular from './ValidarCelular.vue'
import SeleccionarCuota from './SeleccionarCuota.vue'
import EstadoCuenta from './EstadoCuenta.vue'
import PagoAnticipado from './PagoAnticipado.vue'
import RespuestaTransaccion from './RespuestaTransaccion.vue'
import ValidarIdentidad from './ValidarIdentidad.vue'
import ValorPersonalizado from './ValorPersonalizado.vue'
import MetodoPago from './MetodoPago.vue'
import MetodoPagoPse from './MetodoPagoPse.vue'
import MetodoPagoEfecty from './MetodoPagoEfecty.vue'
import MetodoPagoTarjeta from './MetodoPagoTarjeta.vue'
import SeleccionarComercio from './SeleccionarComercio.vue'
import { mapState, mapMutations } from 'vuex';
export default {
  name: 'Home',
  components: {
    ValidarDocumento,
    ValidarCelular,
    SeleccionarCuota,
    EstadoCuenta,
    PagoAnticipado,
    RespuestaTransaccion,
    ValidarIdentidad,
    MetodoPago,
    ValorPersonalizado,
    MetodoPagoPse,
    MetodoPagoEfecty,
    MetodoPagoTarjeta,
    SeleccionarComercio,
  },
  computed:{
    ...mapState(['procesoActual','showLogout','documentoCliente']),
  },
  methods:{
    ...mapMutations(['cambiarProceso']),
  },
}
</script>