<template>
    <div class="modal fade center" id="modalCodigoInvalido" tabindex="-1" role="dialog" aria-labelledby="modalCodigoInvalidoLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="text-center">
              <div class="text-right">
                <img src="@/assets/times-solid.svg" alt="Cerrar" @click="cerrarModal()" style="cursor: pointer;">
              </div>
              <div class="p-3">
                <figure>
                  <img src="@/assets/advertencia.svg" alt="">
                </figure>
                <h2 class="mt-4" style="color:#2E1380;">Código incorrecto…</h2>
                <p class="mt-3" style="color:#000;">No es posible validar la identidad del titular, el código ingresado no es válido. Intenta nuevamente.</p>
                <button class="w-100 text-center btn btn-outline-primary fw-bold mt-4" @click="cerrarModal()">Volver</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import $ from 'jquery'
export default{
  methods:{
    cerrarModal(){
      $("#modalCodigoInvalido").modal('hide');
    }
  }
}
</script>
<style lang="scss">
#modalCodigoInvalido .modal-content{
  border-radius:16px;
  padding: 1.3rem;
  max-width: 430px;
  margin: 0 auto;
}
</style>