<template>
    <Layout :show-back="true" @goBack="$router.back()">
      <div class="container-card-cuota">
        <h2 class="title mb-3">Paga tu Cuota</h2>
        <h3 class="sub-title">Valida tu número celular</h3>
        <p class="subtitle-container">Por favor ingresa el número celular asociado al titular de la cuenta finalizado en: <b v-text="celularEnmascaradoCliente" style="color:#802EFF;"></b></p>
        <div class="container-form">
          <label for="validateTel">Ingresa tu número de celular</label>
          <div class="container-input-doc mb-2" id="container-input-doc">
              <span class="fw-bold">+57</span>
              <input
                id="validateTel"
                type="number"
                placeholder="Confirma tu número de celular"
                v-model="celular"
                @keyup.enter="validarCelular()"
              >
          </div>
          <p id="message-error" style="display:none;">Por favor ingresa un número de celular válido.</p>
          <button
            :disabled="!validateInputs"
            class="button-primary mt-4"
            @click="validarCelular()"
          >
            Continuar
          </button>
            <ErrorsApi/>
        </div>
        <MediosPago />
        <CelularInvalido />
      </div>
    </Layout>
</template>
<script>
import { mapState,mapMutations } from 'vuex'
import ErrorsApi from '../components/ErrorsApi'
import CelularInvalido from './CelularInvalido.vue'
import MediosPago from '../components/MediosPago.vue'
import Layout from '../components/layouts/Layout.vue'
import $ from 'jquery'
export default{
  name: 'ValidarCelular',
  components:{
    ErrorsApi,
    CelularInvalido,
    MediosPago,
    Layout,
  },
  data(){
    return {
      celular: '',
    }
  },
  computed:{
    ...mapState(['celularEnmascaradoCliente','documentoCliente']),
    validateInputs() {
      return this.celular && this.celular.toString().length == 10
    }
  },
  watch: {
    celular(){
      if (this.celular.toString().length > 10) {
        this.celular = this.celular.toString().slice(0, 10)
      }
    },
  },
  mounted() {
    this.$store.commit('changeViewLayout', true)
    this.agregarProcesoAnterior('validar-documento');
  },
  unmounted() {
    this.$store.commit('changeViewLayout', false)
  },
  methods: {
    ...mapMutations(['agregarMessageError','cambiarProceso','gestionarValidarDocumento','gestionarValidarTokenSMS','agregarProcesoAnterior']),
    validarCelular(){
      $("#container-input-doc").removeClass('container-input-doc--sin-borde');
      $("#container-input-doc span").removeClass('input-group-span-error');
      $("#container-input-doc input").removeClass('input-group-input-error');
      $("#message-error").hide();
      if(this.validateInputs){
          return this.$store.dispatch('validarCelular',{'client_phone': this.celular.toString(),'client_document': this.documentoCliente});
      }else{
        $("#container-input-doc").addClass('container-input-doc--sin-borde');
        $("#container-input-doc span").addClass('input-group-span-error');
        $("#container-input-doc input").addClass('input-group-input-error');
        $("#message-error").show();
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.container-card-cuota {
  text-align: start;
  label {
    color: black;
  }
  .title {
    font-size: 28px;
    font-weight: 800;
    max-width: 350px;
    margin-right: auto;
    margin-left: auto;
    color: #2E1380;
  }
  .subtitle-container {
    color: #7F7FB4;
    text-align: start;
    max-width: 350px;
    margin-right: auto;
    margin-left: auto;
  }
  .sub-title {
    color: #7633FF;
    max-width: 350px;
    margin-right: auto;
    margin-left: auto;
    font-size: 24px;
    margin-bottom: 1rem;
  }
}
</style>